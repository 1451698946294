/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
@import "/node_modules/angular-notifier/styles";


.size-icon{
    font-size:15px;
}

.border{
    border-bottom:3px solid;
    padding: 25px 4px 28px 4px;
}


.alert{
    padding: 5px;
    background: #f5e8e8;
}

.erro-card{
    background: #eb4d4d !important;
    padding: 18px;
    #erro{
        color: white !important;
    }
    .erro-size{
      font-size: 20px;
    }
}

.success-card{
    font-size: 24px;
    color: white !important;
    border: 3px solid #1a5202;
    background: #4caf50 !important;
    padding: 4px;
    margin: 27px;
    
}

